var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"materials"},[_c('UserData'),(_vm.recommendations && Object.keys(_vm.recommendations).length)?_c('div',[(
        _vm.user &&
        _vm.user.specialities &&
        _vm.user.specialities.length &&
        _vm.user &&
        _vm.user.diseases &&
        _vm.user.diseases.length
      )?_c('div',[_c('div',{staticClass:"materials__title mb-4"},[_vm._v("Материалы для вас:")]),_c('div',{staticClass:"\n          button button_pink\n          px-12\n          d-inline-flex\n          mb-4\n          materials__filter-button\n          d-xl-none\n        ",on:{"click":_vm.openFilter}},[_c('svg',{staticClass:"mr-2",attrs:{"width":"25","height":"24","viewBox":"0 0 25 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M19.5078 12H21.6191","stroke":"white"}}),_c('path',{attrs:{"d":"M3.38281 12H15.0116","stroke":"white"}}),_c('path',{attrs:{"d":"M10.4531 5.98828H21.6206","stroke":"white"}}),_c('path',{attrs:{"d":"M3.38281 5.98828H5.96281","stroke":"white"}}),_c('path',{attrs:{"d":"M13.625 18.0117H21.62","stroke":"white"}}),_c('path',{attrs:{"d":"M3.38281 18.0117H9.12781","stroke":"white"}}),_c('path',{attrs:{"d":"M8.20703 8.125C9.44967 8.125 10.457 7.11764 10.457 5.875C10.457 4.63236 9.44967 3.625 8.20703 3.625C6.96439 3.625 5.95703 4.63236 5.95703 5.875C5.95703 7.11764 6.96439 8.125 8.20703 8.125Z","stroke":"white"}}),_c('path',{attrs:{"d":"M17.2578 14.25C18.5005 14.25 19.5078 13.2426 19.5078 12C19.5078 10.7574 18.5005 9.75 17.2578 9.75C16.0152 9.75 15.0078 10.7574 15.0078 12C15.0078 13.2426 16.0152 14.25 17.2578 14.25Z","stroke":"white"}}),_c('path',{attrs:{"d":"M11.375 20.2617C12.6176 20.2617 13.625 19.2544 13.625 18.0117C13.625 16.7691 12.6176 15.7617 11.375 15.7617C10.1324 15.7617 9.125 16.7691 9.125 18.0117C9.125 19.2544 10.1324 20.2617 11.375 20.2617Z","stroke":"white"}})]),_vm._v(" Фильтр ")]),_c('MaterialsCategories',{staticClass:"mb-7"}),(
          _vm.recommendationsList.text_materials &&
          _vm.recommendationsList.text_materials.length
        )?_c('MaterialsSlider',{key:("text_" + (_vm.recommendationsList.text_materials.length)),staticClass:"mb-12",attrs:{"title":'Публикации',"count":_vm.recommendationsList.text_materials.length},scopedSlots:_vm._u([{key:"items",fn:function(){return _vm._l((_vm.recommendationsList.text_materials),function(item,i){return _c('Material',{key:("text" + i),attrs:{"item":item,"page":'TextDetail'},on:{"watchLater":function($event){return _vm.watchLater(
                item.watch_later ? 'DELETE' : 'POST',
                item.slug,
                'text_material'
              )},"favorite":function($event){return _vm.favorite(
                item.favorite ? 'DELETE' : 'POST',
                item.slug,
                'text_material'
              )}}})})},proxy:true}],null,false,1339544044)}):_vm._e(),(
          _vm.recommendationsList.podcasts && _vm.recommendationsList.podcasts.length
        )?_c('MaterialsSlider',{key:("podcasts_" + (_vm.recommendationsList.podcasts.length)),staticClass:"mb-12",attrs:{"title":'Подкасты',"count":_vm.recommendationsList.podcasts.length},scopedSlots:_vm._u([{key:"items",fn:function(){return _vm._l((_vm.recommendationsList.podcasts),function(item,i){return _c('Material',{key:("pod" + i),attrs:{"item":item,"page":'PodcastDetail'},on:{"watchLater":function($event){return _vm.watchLater(
                item.watch_later ? 'DELETE' : 'POST',
                item.slug,
                'podcast'
              )},"favorite":function($event){return _vm.favorite(
                item.favorite ? 'DELETE' : 'POST',
                item.slug,
                'podcast'
              )}}})})},proxy:true}],null,false,1893234494)}):_vm._e(),(
          _vm.recommendationsList.video_materials &&
          _vm.recommendationsList.video_materials.length
        )?_c('MaterialsSlider',{key:("video_" + (_vm.recommendationsList.video_materials.length)),staticClass:"mb-12",attrs:{"title":'Видеоматериалы',"count":_vm.recommendationsList.video_materials.length},scopedSlots:_vm._u([{key:"items",fn:function(){return _vm._l((_vm.recommendationsList.video_materials),function(item,i){return _c('Material',{key:("vid" + i),attrs:{"item":item,"page":'VideoDetail'},on:{"watchLater":function($event){return _vm.watchLater(
                item.watch_later ? 'DELETE' : 'POST',
                item.slug,
                'video_material'
              )},"favorite":function($event){return _vm.favorite(
                item.favorite ? 'DELETE' : 'POST',
                item.slug,
                'video_material'
              )}}})})},proxy:true}],null,false,1142443306)}):_vm._e(),(
          _vm.recommendationsList.diseases && _vm.recommendationsList.diseases.length
        )?_c('MaterialsSlider',{key:("disease_" + (_vm.recommendationsList.diseases.length)),staticClass:"mb-12",attrs:{"title":'Обновления в заболеваниях',"count":_vm.recommendationsList.diseases.length},scopedSlots:_vm._u([{key:"items",fn:function(){return _vm._l((_vm.recommendationsList.diseases),function(disease,i){return _c('DiseaseUpdate',{key:("updis" + i),attrs:{"item":disease}})})},proxy:true}],null,false,4145606497)}):_vm._e(),(_vm.recommendationsList.drugs && _vm.recommendationsList.drugs.length)?_c('MaterialsSlider',{key:("drugs_" + (_vm.recommendationsList.drugs.length)),staticClass:"mb-12",attrs:{"title":'Препараты',"count":_vm.recommendationsList.drugs.length},scopedSlots:_vm._u([{key:"items",fn:function(){return _vm._l((_vm.recommendationsList.drugs),function(drug,i){return _c('Medication',{key:("drug" + i),attrs:{"item":drug},on:{"watchLater":function($event){return _vm.watchLater(
                _vm.item.watch_later ? 'DELETE' : 'POST',
                _vm.item.slug,
                'drug'
              )},"favorite":function($event){return _vm.favorite(_vm.item.favorite ? 'DELETE' : 'POST', _vm.item.slug, 'drug')}}})})},proxy:true}],null,false,2888866913)}):_vm._e(),(
          _vm.recommendationsList.projects &&
          _vm.recommendationsList.projects.length
        )?_c('MaterialsSlider',{key:("project_" + (_vm.recommendationsList.projects.length)),attrs:{"title":'Проекты',"count":_vm.recommendationsList.projects.length},scopedSlots:_vm._u([{key:"items",fn:function(){return _vm._l((_vm.recommendationsList.projects),function(item,i){return _c('Project',{key:i,attrs:{"item":Object.assign({}, item, {type: { name: 'Проекты' }})},on:{"watchLater":function($event){return _vm.watchLater(
                item.watch_later
                  ? 'DELETE'
                  : 'POST',
                item.id,
                'project'
              )},"favorite":function($event){return _vm.favorite(
               item.favorite
                  ? 'DELETE'
                  : 'POST',
                item.id,
                'project'
              )}}})})},proxy:true}],null,false,2272652194)}):_vm._e()],1):_vm._e(),(
        _vm.user &&
        _vm.user.specialities &&
        _vm.user.specialities.length &&
        _vm.user &&
        _vm.user.diseases &&
        _vm.user.diseases.length &&
        !_vm.notEmpty
      )?_c('div',{staticClass:"favorites__list-container"},[_c('div',{staticClass:"favorites__title"},[_vm._v("Здесь пока пусто")]),_c('div',{staticClass:"favorites__descr"},[_vm._v(" Чтобы увидеть список материалов для вас - выберите хотя бы одну терапевтическую область и одну нозологию ")])]):(
        _vm.user &&
        _vm.user.specialities &&
        !_vm.user.specialities.length &&
        _vm.user &&
        _vm.user.diseases &&
        !_vm.user.diseases.length
      )?_c('div',{staticClass:"favorites__list-container"},[_c('div',{staticClass:"favorites__title"},[_vm._v("Здесь пока пусто")]),_c('div',{staticClass:"favorites__descr"},[_vm._v(" Чтобы увидеть Материалы для вас - пройдите тест ")]),_c('div',{staticClass:"favorites__btn button button_pink mt-6",on:{"click":function($event){return _vm.toSettings({ name: 'MaterialsSettings' })}}},[_vm._v(" Настроить Мой AZ-MOST ")])]):_vm._e()]):_c('div',{staticClass:"preloader"},[_c('Preloader',{})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }