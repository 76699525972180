<template>
  <router-link :to="{name: 'Disease', params: {slug: item.parent_slug, disease: item.slug, block_id: item.child_id}}" class="disease-update">
    <div class="disease-update__tag">{{item.label}}</div>
    <div class="disease-update__name" v-html="item.title ">
    </div>
    <div class="disease-update__section-title" v-html="`Раздел: ${item.name}`">
    </div>
    <div class="disease-update__date">{{item.created_at ? $m(item.created_at).format("DD MMMM YYYY") : $m(item.updated_at).format("DD MMMM YYYY")}}</div>
  </router-link>
</template>

<script>
export default {
  name: "DiseaseUpdate",
  props: {
    item: Object
  }
};
</script>

<style lang="scss" scoped>
.disease-update {
  padding: 16px;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  height: 100%;

  &:hover {
    .disease-update__section-title {
      color: #830051;
    }
  }

  &__tag {
    margin-bottom: 16px;
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #3c4242;
    border: 1px solid #d8dada;
    border-radius: 99px;
  }

  &__name {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #830051;
    margin-bottom: 8px;
  }

  &__section-title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #3c4242;
    margin-bottom: 8px;
    transition: .3s;
  }

  &__date {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }
}
</style>